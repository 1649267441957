import { template as template_2f3312b511c64e85b4550827fb1b4d44 } from "@ember/template-compiler";
const FKControlMenuContainer = template_2f3312b511c64e85b4550827fb1b4d44(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
