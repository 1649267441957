import { template as template_778b1777ee414b359208164d9d2f9a9b } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_778b1777ee414b359208164d9d2f9a9b(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
