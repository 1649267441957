import { template as template_57499e013be745f7b928c2a63201e0b4 } from "@ember/template-compiler";
const FKText = template_57499e013be745f7b928c2a63201e0b4(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
